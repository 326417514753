<template>
  <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('tcbconfiguration.district_dealer_allocation') }} ({{ $t('allocation_management.regional_office') }})</h4>
        </template>
        <!-- <template v-slot:searchHeaderAction>
          <router-link class="btn-add" to="beneficiary-entry"><i class="ri-arrow-left-line"></i> {{ $t('tcbconfiguration.beneficiary_entry') }} {{ $t('globalTrans.list') }}</router-link>
        </template> -->
      <b-container fluid>
      <b-row>
        <b-overlay :show="loadingState">
                <b-row>
                    <!-- -----------------Add More Start------------------- -->
                    <div class="table-wrapper table-responsive mt-2">
                      <b-table-simple class="table table-striped table-hover table-bordered ">
                        <b-thead>
                          <b-tr>
                            <b-th width="5%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th width="25%" class="text-center">{{ $t('allocation_management.regional_office') }}</b-th>
                            <b-th class="text-center">{{ $t('globalTrans.district') }}</b-th>
                            <b-th width="5%" class="text-center">{{ $t('globalTrans.action') }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <template v-if="regionData && regionData.length">
                              <b-tr v-for="(region, index) in regionData" :key="index">
                                  <b-td class="text-center">{{ index+1 }}</b-td>
                                  <b-td class="text-center">{{ regionName(region.value) }}</b-td>
                                  <b-td class="text-center">
                                    <span v-for="(district, index2) in region.districtIds" :key="district">{{ getDistrictName(district) }}<span v-if="index2 < region.districtIds.length - 1">, </span> </span>
                                  </b-td>
                                  <b-td class="text-center">
                                    <b-button class="btn btn-sm btn-info" :title="$t('globalTrans.edit')" v-b-modal.modal-form-district @click="districtAllocation(region)"><i class="ri-pencil-fill"></i></b-button>
                                  </b-td>
                              </b-tr>
                          </template>
                          <b-template v-else>>
                            <b-tr>
                              <b-td colspan="4" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                            </b-tr>
                          </b-template>
                        </b-tbody>
                      </b-table-simple>
                    </div>
                    <!-- -----------------Add More End--------------------- -->
                </b-row>
            </b-overlay>
      </b-row>
  </b-container>
  </card>
    <b-modal id="modal-form-district" size="lg" :title="$t('tcbconfiguration.district_dealer_allocation')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <FormDistrict :region="region" :key="region.value"/>
    </b-modal>
  </div>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { regionDistrictDealerAllocationList } from '../../../api/routes'
import FormDistrict from './Form.vue'
import ModalBaseMasterList from '@/mixins/list'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormDistrict
  },
name: 'Form',
props: ['id'],
data () {
  return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      loading: false,
      regionData: [],
      region: '',
      form: {
          district_id: 0,
          districts: 0
      },
      is_disable: false
  }
},
created () {
  this.loadData()
},
computed: {
  regionalOfficeList () {
    return this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.org_id === 7 && item.is_regional_office === 1)
  },
  districtList: function () {
     return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1)
  }
},
watch: {
},
methods: {
  districtAllocation (region) {
    this.region = region
  },
  async loadData () {
    this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
    this.is_disable = true
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, regionDistrictDealerAllocationList)
        if (result.success) {
          const regions = result.data
          const dealerRegion = result.regionDealer

            this.regionData = this.regionalOfficeList.map(item => {
                const matchingRegions = regions.find(regional => regional.regional_office_id === item.value)
                const matchingRegions2 = dealerRegion.find(regional => regional.regional_office_id === item.value)

                if (matchingRegions) {
                    return {
                        ...item,
                        districts: matchingRegions.district_details,
                        districtIds: matchingRegions2 !== undefined ? matchingRegions2.districts : []
                    }
                }

                return item
            })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    regionName (region, lang = this.$i18n.locale) {
      const data = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.org_id === 7 && item.is_regional_office === 1).find(item => item.value === region)
      if (data !== undefined) {
        if (lang === 'bn') {
          return data.text_bn
        } else {
          return data.text_en
        }
      }
    },
    getDistrictName (disId) {
        const wardObj = this.$store.state.CommonService.commonObj.districtList.find(item => item.value === disId)
        if (wardObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return wardObj.text_bn
            } else {
                return wardObj.text_en
            }
        }
    }
  }
}
</script>
