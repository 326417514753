<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
        <b-row>
          <b-col lg="12" sm="12" md="12">
            <ValidationProvider name="District" vid="district">
                <b-form-group
                class="row"
                label-cols-sm="12"
                label-for="district"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('globalTrans.select') }} <span class="text-danger">*</span>
                </template>
                <b-form-checkbox-group
                    class="text-dark"
                    v-model="form.districtIds"
                    :options="districtList"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-checkbox-group>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form-district')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { regionDistrictDealerAllocationStore } from '../../../api/routes'
export default {
  name: 'Form',
  props: ['region'],
  data () {
    return {
      valid: null,
      saveBtnName: this.region.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      districtIds: [],
      form: {
        districtIds: [],
        regional_office_id: 0
      }
    }
  },
  created () {
    this.districtIds = this.region.districts.map(item => item.district_id)
    this.form.districtIds = this.region.districtIds
    this.form.regional_office_id = this.region.value
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    districtList () {
        return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && this.districtIds.includes(item.value))
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      result = await RestApi.postData(licenseRegistrationServiceBaseUrl, regionDistrictDealerAllocationStore, this.form)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('LicenseRegistrationService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form-district')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
